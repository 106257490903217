@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Barlow');

$success: #7FBC42;

// $theme-colors: (
//   success: $success,
// // now bg-accent,btn-accent,etc.. can be called
// );

@import "../node_modules/bootstrap/scss/bootstrap";

:root {
  --big-font: calc(10px + 2vmin);
  --medium-font: calc(10px + 1.4vmin);
  --small-font: calc(10px + 0.75vmin);
}

.InfoBox {
  border-radius: 10px;
  border-width: 2px;
  border-style: dotted;
  border-color: rgba(0, 0, 0, .25);
  background-color: rgba(240, 178, 5, .25);
}

.SignUpBox {
  border-radius: 10px;
  border-width: 5.5px;
  border-style: ridge;
  border-color: $success;
}

.SignInBox {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2em;
}

.BorderBox {
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(0, 0, 0, .25);
}

.App {
  font-family: 'Barlow', sans-serif;
}

.SystemDesigner {
  margin-left: auto;
  margin-right: auto;
}

.SystemDesigner__Body {
  
}

.EmailReport {
  font-family: 'Barlow', sans-serif;
  margin: 20px;
}

.section {
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}

.field {
  margin-bottom: 20px;
}

.padding {
  height: 22px;
}

.auto-centered {
  min-width: 75vw;
  min-height: 85vw;
  display: flex;
  flex-direction: column;
  align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.transparent {
  background-color: rgba(255,255,255,0.6);
  margin-top: 5vh;
  margin-bottom: 5vh;
  border-radius: 10px;
}

.semitransparent {
  background-color: rgba(255,255,255,0.6);
  border-radius: 10px;
}

.ag-cell-wrap-text {
  word-break: break-word;
}

.ag-theme-alpine {
  font-size: 16px;
  font-family: 'Barlow', sans-serif;
}

.ag-theme-dark {
  font-size: 12px;
  font-family: 'Barlow', sans-serif;
}

.big-font {
  font-size: 22px;
}

.medium-font {
  font-size: 18px;
}

.fields {
  font-size: 22px;
}

.instructions {
  font-size: 22px;
  margin-top: 10px;
  display: inline-block;
}

.sub-header {
  margin-top: 14px;
  margin-bottom: 14px;
  font-size: 28px;
  font-weight: bold;
}

h1 {
  position: inline-block;
}

h2 {
  position: inline-block;
}

h3 {
  position: inline-block;
}